.bg-promo {
    background-position-y: bottom;
    background-size: cover;
}

.aos-animate.aos-init.bg-cover.col-12.col-lg-8.end-0.mt-0.position-absolute.top-0 {
    background-color: rgb(0, 3, 51);
    width: 500px;
    z-index: 50;
    padding: 50px;
    margin-top: 400px !important;
}

form.promo {
    padding-left: 0;
        padding-right: 0;
        background-color: rgb(0, 3, 51);
        padding-bottom: 20px;
}

input.form-control {
    padding: 0.75rem 0.75rem;
    background-color: #e9e9e9;
    text-align: center;
}

.img-bg-header-register {
    /* z-index: -1;*/
    background-size: cover;
    background-position-y: bottom;
}

input.register {
    padding: 0.75rem 0.75rem;
}

img.logo-promo {
    width: 512px;
    margin-left: 250px;
}

.col-lg-7.py-vh-6.position-relative.aos-init.aos-animate {
    padding-top: 20px;
}

h1.display-1.fw-bold.promo {
    color: #ffff;
    font-size: 3rem;
}

label.form-label.promo {
    color: #fff;
    font-size: 14px;
}

select#tienda {
    padding: 0.75rem 0.75rem;
    width: 100%;
    line-height: 1.5;
    background-color: #e9e9e9;
    height: 50px;
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 300;
    color: #333;
}

button.btn.btn-primary.promo {
    background-color: #fdc100;
    font-weight: 400;
    font-size: .875rem;
    font-family: myriadSemibold, Arial;
    transition: all .5s ease;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 0.5rem 1.25rem 0.5rem;
    font-family: myriadSemibold, Arial;
}

.fw-bold {
    font-weight: 500 !important;
    text-transform: uppercase;
}

h3.py-5.border-top.border-dark.aos-init.aos-animate {
    color: #FFF !important;
    font-size: 3rem !important;
    padding-left: 42%;
}

label.form-check-label {
    color: #fff;
}

strong.form-check {
    color: #FFF;
    display: inline-flex;
    padding-left: 0px;
}

span.h5.fw-lighter {
    overflow: hidden;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0 18px 10px 0 rgb(0 0 0 / 7%);
    border-radius: 180px;
    font-size: 4rem;
    color: #fff;
}

span.h5.fw-lighter:hover::before {
    transform: skewY(5.5deg) translate3d(0, -6px, 0);
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    position: relative;
    z-index: 1;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding: 20px 15px 25px 20px;
    border-radius: 5px;
}

.py-5:hover::before {
    transform: skewY(5.5deg) translate3d(0, -6px, 0);
}

span.feeds.h5 {
    font-weight: 600;
    font-size: 4rem;
    color: #fff;
    text-shadow: 0 0 #000;
    transition: all .5s ease;
}

.girar {
    transition: all .5s ease;
}

.girar:hover {
    transition: all .5s ease;
    transform: rotate(360deg);
}

.py-vh-4.bg-gray-100.w-100.overflow-hidden {
    margin-top: -100px !important;
}

.navbar {
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 0.0625rem solid #f2f2f2;
}

.container.terminos {
    padding-top: 10%;
}

.navbar-expand-lg .navbar-nav .nav-link {
    font-weight: 400 !important;
}

h2.display-6.promo {
    font-weight: 400;
    border-bottom: 0.0625rem solid #f2f2f2;
}

.form-promo-register {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-right: 150px;
    margin-left: 150px;
    margin-top: 10px
}

header.col-12.promo-register {
    padding-top: 10px;
    height: 100px;

}

img.logo-promo-register {
    max-width: 35%;
}

.form-control {
    border: 1px solid #333 !important;
}

.promo-feeds {
    display: flex;
}

.promo-feed-padding {
    padding-top: 9vh;
}

.logo-property {
    width: 250px;
}

.form-promo-landing {
    margin-right: -150px;
}

.img-bg-secundary-register {
    background-size: contain;
    background-position-y: top;
    background-repeat: no-repeat;
    text-align: center;

}

@media (max-width: 1366px) {
    .aos-animate.aos-init.bg-cover.col-12.col-lg-8.end-0.mt-0.position-absolute.top-0 {
        margin-right: -100px;
    }
}

@media (width: 820px) {
    .aos-animate.aos-init.bg-cover.col-12.col-lg-8.end-0.mt-0.position-absolute.top-0 {
        margin-right: -50px;
        margin-top: 700px !important;
    }

    h2.display-6.promo {
        padding-right: 70% !important;
    }

    .promo-feeds {
        display: inline-block;
    }

    .promo-feed-padding {
        padding-top: 2vh;
    }

    .logo-property {
        max-width: 60%;
    }

    .img-bg-header-register {
        z-index: -1;
        background-size: contain;
        background-position-y: top;
        background-repeat: no-repeat;
    }

    .form-promo-register {
        margin-left: 80px;
        margin-right: 10px;
    }

    input.register {
        padding: 0.2rem 0.2rem;
    }

    img.logo-promo-register {
        max-width: 25%;
    }

}

@media (min-width: 992px) {
    .form-promo-landing {
        margin-right: -90px;
        margin-top: 550px !important;
    }
}

@media (width: 1680px) {
    .form-promo-landing {
        margin-right: -20px;
    }
}

@media screen and (max-width: 768px) {
    .promo-feeds {
        display: inline-block;
    }

    h2.display-6.promo {
        padding-right: 50% !important;
    }

    .form-promo-landing {
        margin-right: -10px !important;
    }

    img.logo-promo {
        width: 412px;
        margin-left: 0px;
    }

    .aos-animate.aos-init.bg-cover.col-12.col-lg-8.end-0.mt-0.position-absolute.top-0 {
        margin-top: 300px !important;
        width: 200px;
        padding: 10px;
    }

    .form-control {
        width: 90%;
    }

    h1.display-1.fw-bold.promo {
        font-size: 2rem !important;
    }

    span.feeds.h5 {
        font-size: 2rem;
    }

    .promo-feed-padding {
        padding-top: 2vh;
        width: 50%;
    }

    .img-bg-secundary-register {
        background-position-y: bottom;
        background-size: contain;
    }

    .img-bg-header-register {
        background-size: cover;
        background-position-y: top;
        background-repeat: no-repeat;
    }

    .form-promo-register {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0%;
    }

    input.register {
        padding: 0.1rem 0.1rem;
    }

    form.promo {
        padding-right: 10px;
        padding-bottom: 50px;
    }

    img.logo-promo-register {
        max-width: 100%;
    }


}